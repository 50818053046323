.nav-tabs {
  border: none;
  margin: 0 auto;
}
.nav-tabs .nav-link {
  background-color: #eee !important;
  color: #2f78b7 !important;
  border: 1px solid #d0d8e3;
  /* border: 1px solid #ddd; */
  margin-right: 2px;
}
.nav-tabs .nav-link:hover {
  color: #6e9fe2 !important;
  border-top: 4px solid #8dabab;
  border-left: 1px solid #8dabab;
  border-right: 1px solid #8dabab;
}
.nav-tabs .active {
  border-bottom: 0 !important;
  border-top: 4px solid #008080 !important;
  position: relative;
  color: #444444 !important;
  background: #fff !important;
  border-left: 1px solid #d0d8e3;
  border-right: 1px solid #d0d8e3;
}
.nav-tabs .active:hover {
  background: none !important;
  color: #6e9fe2 !important;
}
.tab-pane {
  /* margin-top: -1px !important; */
  background-color: #fff !important;
  border: 1px solid #ddd !important;
  min-width: inherit;
  margin-bottom: 40px;
}


.nav-tabs .tab-disabled {
  /* background-color: #D0D0D0 !important; */
  /* color: #4A4A4A !important; */
  /* color: #a2a2a2 !important; */
  /* color: #8f8f8f !important; */
  /* color: #858585 !important; */
  color: #7b7b7b !important;
  /* color: #676767 !important; */

}

.tab-content-padding {
  padding-top: 20px !important;
  padding-bottom: 30px !important;
}
/* .tab-content-border {
	padding: 30px;
	-webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
	border-radius: 4px;
} */

/* .tab-pane {
	border: 1px solid red ;
} */
/* .tab-content .tab-pane,
.tab-content .container {
	border-top: 0 !important;
} */

/* .nav-tabs .nav-link .nav-tabs .nav-link,
.nav-tabs .nav-link {
	background-color: #eee !important;
	color: #2f78b7 !important;
	border: 1px solid #d0d8e3;
	margin-right: 2px;
} */

/* .nav-tabs .active,
.nav-tabs .active:hover,
.nav-tabs .active:focus {
	background-color: #2f78b7 !important;
	color: white !important;
	border: 1px solid #d0d8e3;
} */

.select-menu-adv {
  background-color: white !important;
  padding-top: 10.5px !important;
  padding-bottom: 10.5px !important;
}

.auto-input-root {
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.input-auto {
  padding-left: 14px !important;
  padding-top: 10.5px !important;
  padding-bottom: 10.5px !important;
}

.auto-option {
  padding-left: 14px !important;
}

.categorized-auto-option {
  padding-left: 28px !important;
}

.categorized-auto-label {
  padding-left: 14px !important;
  font-weight: bold !important;
}

.select-menu {
  background-color: white !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.select-lbl-inline {
  font-weight: bold !important;
}

.gly-rng-input {
  width: 90px !important;
}

.pro-rng-input {
  width: 125px !important;
}

.search-lbl {
  margin-left: -27px !important;
  font-weight: bold !important;
}

.list-toggle-lbl {
  font-weight: bold !important;
}

.comp-search-label-header {
  font-weight: bold !important;
  margin: 0px !important;
  color: #2f78b7;
}

.comp-search-label-subtext {
  font-size: 12px !important;
  font-weight: bold !important;
  font-style: italic;
}

.card-row {
  background-color: #fff !important;
}

.card-row:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.card-row:hover {
  background-color: #dbe4ec !important;
}

.card-row-even {
  background-color: #fff !important;
}

.card-row-even:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.card-row-even:hover {
  background-color: #dbe4ec !important;
}

.global-search-card {
  margin: 16px;
  padding: 0px;
}

.qs-search-lbl {
  font-weight: bold !important;
}

.svg-node {
  fill: #2F78B7;
  stroke: #afd9fd;
  stroke-width: 0px;
  cursor: pointer  !important;
}

.svg-node-text {
  fill: #fff;
  font-size: 13px;
  font-weight: bold;
  text-anchor: middle;
  cursor: pointer  !important;
}

.svg-link {
  stroke: black;
  stroke-width: 1.5;
}

.svg-link-text {
  font-size: 13px;
  font-weight: 200;
  fill: #000;
  text-anchor: middle;
  stroke-width: 0.8;
}

.svg-link-text-number {
  font-size: 10px;
  font-weight: 200;
  fill: #000;
  stroke: #2F78B7;
  text-anchor: middle;
  cursor: pointer  !important;
  stroke-width: 0.8;
}

.svg-link-text-number-nl {
  font-size: 10px;
  font-weight: 200;
  fill: #000;
  stroke: gray;
  text-anchor: middle;
  cursor: default  !important;
  stroke-width: 0.8;
}

.svg-link-text-number-disabled {
  display: none;
}

.svg-numnode {
  fill: #ffff99;
  stroke: #2F78B7;
  stroke-width: 1.5px;
  cursor: pointer  !important;
}

.svg-numnode-nl {
  fill:#DCDCDC;
  stroke: #2F78B7;
  stroke-width: 1.5px;
  cursor: no-drop  !important;
}

.svg-numnode-text {
  fill: #000;
  stroke: #000;
  stroke-width: 0.8px;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  text-anchor: middle;
  cursor: pointer  !important;
}

.svg-numnode-text-nl {
  fill: #000;
  stroke: #000;
  stroke-width: 0.8px;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  text-anchor: middle;
  cursor: no-drop  !important;
}

.svg-input-container {
  box-sizing: border-box;
  padding-bottom: 10px;
}

.svg-input-item {
  padding-right: 16px;
}

.svg-input {
  background-color: white !important;
}

.svg-tooltip-text {
  padding: 5px;
  font-weight: 500;
  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.87) !important;
  font-size: 16px !important;
  border: 1px solid #eee !important;
  -webkit-box-shadow: 5px 10px 40px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 5px 10px 40px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.svg-tooltip {
  width: fit-content;
  z-index: 500 !important;
  margin: 0;
  padding: 0;
}

.svg-arrow-up {
  width: 0; 
  height: 0; 
  margin-left: 10px;
  margin-top: -2px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #4a4a4a;
}

.btn-supsearch-top {
  margin-top: -1.5rem !important;
}
